import React, {Component} from 'react';
import { Redirect } from 'react-router-dom';
import {Modal, ModalBody, ModalHeader} from 'reactstrap';
import { unmountComponentAtNode } from "react-dom";
import {base, appDatabasePrimaryFunctions} from '../../base';
import { confirmAlert } from '../utils/react-confirm-alert';
import Confetti from 'react-confetti';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from 'react-responsive-carousel';
import { getDistance } from 'geolib';
import Loading from '../utils/Loading';
import ReactPlayer from 'react-player';
import '../../styles/css/Home.css';
import '../../styles/css/ConfirmAlertCustom.css';
import '../../styles/css/main.css';
import '../../styles/css/CreatorAwards.css';
import fileDownload from 'js-file-download';
import { validateEmail } from '../utils/HelpfulFunction';
import { Textfit } from 'react-textfit';
const Axios = require('axios');

class Main_App extends Component {
    constructor(props) {
        super(props);
        this.state = {
            userEmail: sessionStorage.getItem('userEmail') || false,
            gameActive: false,
            seenVideo: false,
            tenantRules: {},
            currentPick: {},
            winner: "",
            racers: [],
            selectedItem: 0,
            videoState: 0,
            status: 0,
            timeToSendEmails: null,
            videoDuration: "0:00",
            width: 0,
            height: 0,
            highlightedPick: false,
            questionAnswered: false,
            formFilledOut: false,
            userAnswers: [],
            alreadyUpdate: "",
            currentGameId: null,
            locationChecking: true,
            loading: true,
            startConfetti: false,
            downloadingImage: false,
            downloadedFile: null,
            imageLoading: true
        };
        this.logout = this.logout.bind(this);
        this.toggle = this.toggle.bind(this);
        this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
        this.onEnded = this.onEnded.bind(this);
        this.winnerToggle = this.winnerToggle.bind(this);
        this.toggleRules = this.toggleRules.bind(this);
    }

    handleGameMethods(){
        const userEmail = this.state.userEmail;
        let base64EncodedEmail = btoa(userEmail);
        if(!validateEmail(userEmail)){
            base64EncodedEmail = userEmail;
        }
        this.currentGameKeyRef = base.listenTo(`currentGame/id`, {
            context: this,
            then(key){
                this.logUserActivity(key)
                this.removeReactAlert();
                this.setState({
                    currentGameId:key,
                    highlightedPick: false
                })
            }
        });
        this.gameActiveRef = base.bindToState('currentGame/active', {
            context: this,
            state: 'gameActive'
        })
        this.pickWinnerRef = base.bindToState('currentGame/pickWinner', {
            context: this,
            state: 'pickWinner'
        })
        this.racersRef = base.bindToState('currentGame/racers', {
            context: this,
            state: 'racers',
            asArray: true
        })
        this.currentPickRef = base.bindToState('userPicks/'+base64EncodedEmail, {
            context: this,
            state: 'currentPick',
            then(){
                if(this.props.variables && this.props.variables.collectDistance && !this.state.currentPick.pickid && this.props.variables.latitude && this.props.variables.acceptableDistance){
                    this.checkUsersLocation();
                } else {
                    this.setState({
                        locationChecking: false,
                        loading:false
                    })
                }
            }
        })
        this.winnerRef = base.listenTo('currentGame/winner', {
            context: this,
            state: 'winner',
            then(winner){
                this.setState({
                    winner: winner
                });
            }
        })
        this.prizesWonRef = base.listenTo('prizeWon/'+base64EncodedEmail, {
            context: this,
            then(winnerPrize){
                if(winnerPrize && winnerPrize.uid){
                    this.setState({
                        wonPrize: true,
                        rewardName: winnerPrize.rewardName,
                        winningPrizeImage: winnerPrize.rewardImage,
                        winningCode: winnerPrize.code,
                        isWinning: winnerPrize.isWinning
                    }, () => {
                        if(this.props.variables && this.props.variables.doNotCollectEmail){
                            this.attachWinningImageToCanvas(winnerPrize.rewardImage, winnerPrize.code);
                        }
                    })
                } else {
                    this.setState({
                        wonPrize: false,
                        rewardName: null,
                        winningPrizeImage: null,
                        winningCode: null
                    })
                }
            }
        });

        this.statusRef = base.listenTo('currentGame/status', {
            context: this,
            state: 'status',
            then(status){
                this.setState({
                    status: status
                });
            }
        })
        this.currentVideoStateRef = base.listenTo('currentGame/videoState', {
            context: this,
            state: 'videoState',
            then(videoState){
                this.setState({
                    videoState: videoState
                }, () => {
                    let racerWinnerVideo;
                    for(const racerIndex in this.state.racers){
                        if(this.state.racers[racerIndex].winner){
                            racerWinnerVideo = this.state.racers[racerIndex].racerWinningVideoLink;
                        }
                    }
                    if(videoState === 2 && this.state.currentPick.pickid && racerWinnerVideo && !this.state.currentPick.seenVideo && !this.state.pickWinner){
                        this.setState({
                            modal:true
                        });
                    } else if(videoState === 2) {
                        this.alertIfWonOrLost();
                    } else if(videoState === 1 && racerWinnerVideo) {
                        this.setState({
                            modal:true
                        });
                    } else if(videoState === 0){
                        this.setState({
                            modal:false,
                            startConfetti: false
                        });
                    }
                })
            }
        })
    }

    componentDidMount() {
      if(!navigator.cookieEnabled){
        alert("You do not have cookies enabled! Please change the settings of your browser");
        return;
      }
      if(window.location.pathname === "/seasonlong"){
          window.history.pushState({name: "browserBack"}, "on browser back click", window.location.href);
          window.history.pushState({name: "browserBack"}, "on browser back click", window.location.href);
          this.handleSeasonLongMethods();
      } else {
          this.handleGameMethods();
      }

      this.tenantRulesRef = base.bindToState(`tenantRules`, {
        context: this,
        state: 'tenantRules',
      });

      this.updateWindowDimensions();
      window.addEventListener('resize', this.updateWindowDimensions);
      this.props.loadPage();
    }

    handleSeasonLongMethods(){
        const userEmail = this.state.userEmail;
        let base64EncodedEmail = btoa(userEmail);
        if(!validateEmail(userEmail)){
            base64EncodedEmail = userEmail;
        }
        this.campaignPicksRef = base.bindToState('campaignPicks/'+base64EncodedEmail, {
            context: this,
            state: 'campaignPick',
            then(){
                if(this.props.variables && this.props.variables.collectDistance && !this.state.campaignPick.pickid && this.props.variables.latitude && this.props.variables.acceptableDistance){
                    this.checkUsersLocation();
                } else {
                    this.setState({
                        locationChecking: false,
                        loading:false
                    })
                }
            }
        })
        this.allRacersListRef = base.bindToState('racerList', {
            context: this,
            state: 'allRacersList',
            asArray: true
        })
    }

    onEnded(){
        this.setState({
            modal: false
        }, () => {
            this.alertIfWonOrLost();
            const userEmail = this.state.userEmail;
            let base64EncodedEmail = btoa(userEmail);
            if(!validateEmail(userEmail)){
                base64EncodedEmail = userEmail;
            }
            if(base64EncodedEmail){
                base.post('userPicks/'+base64EncodedEmail+'/seenVideo', {
                    data: true,
                    then(err){
                        if(err){
                            console.log(err)
                        } else {
                            console.log("success")
                        }
                    }
                })
            }
        })
    }

    removeReactAlert(){
      document.body.classList.remove('react-confirm-alert-body-element')
      const target = document.getElementById('react-confirm-alert')
      if(target){
        unmountComponentAtNode(target)
        target.parentNode.removeChild(target)
      }
      const svg = document.getElementById('react-confirm-alert-firm-svg')
      if(svg){
        svg.parentNode.removeChild(svg)
        document.body.children[0].classList.remove('react-confirm-alert-blur')
      }
    }

    componentWillUnmount() {
        if(window.location.pathname === "/seasonlong") {
            base.removeBinding(this.campaignPicksRef);
            base.removeBinding(this.allRacersListRef);
        } else {
            base.removeBinding(this.currentPickRef);
            base.removeBinding(this.racersRef);
            base.removeBinding(this.gameActiveRef);
            base.removeBinding(this.currentGameKeyRef);
            base.removeBinding(this.winnerRef);
            base.removeBinding(this.statusRef);
            base.removeBinding(this.currentVideoStateRef);
            base.removeBinding(this.prizesWonRef);
            base.removeBinding(this.pickWinnerRef)
        }
        base.removeBinding(this.tenantRulesRef);
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if(prevState.videoState === 2 && this.state.videoState === 0){
            this.removeReactAlert();
            this.setState({
                startConfetti:false,
                winnerModal: false,
                imageLoading: false
            })
        }
    }

    updateWindowDimensions() {
      this.setState({ width: window.innerWidth, height: window.innerHeight });
    }

    winnerToggle() {
        this.setState({
            winnerModal: !this.state.winnerModal,
            startConfetti: false,
            downloadedFile: null
        });
    }

    changeMilesToMeters(milesToConvert){
        return milesToConvert*1609.344;
    }

    getLocation(){
        return new Promise((resolve, reject) => {
            if (!navigator.geolocation) {
                reject("Geolocation is not supported by your browser. Please change browsers to play!");
            } else {
                const toCheckLatitude = this.props.variables.latitude || 51.525;
                const toCheckLongitude = this.props.variables.longitude || 7.4575;
                navigator.geolocation.getCurrentPosition(
                    function(position) {
                        resolve(getDistance({latitude: position.coords.latitude, longitude: position.coords.longitude}, {
                            latitude: toCheckLatitude,
                            longitude: toCheckLongitude,
                        }))
                    },
                    (err) => {
                        if(err.message === "User denied Geolocation"){
                            reject("Position could not be determined because the browser does not have permission.  Please go to your browsers settings to allow it access to your location");
                        } else {
                            console.log(err.message);
                            reject("An unknown error occurred, check your internet connection and try again");
                        }
                    }
                );
            }
        })
    }

    toggle() {
        this.setState({
            modal: !this.state.modal
        });
    }

    toggleRules() {
        this.setState({
            modalRules: !this.state.modalRules,
        });
    }

    toggleSupport(){
        this.setState({
            modalSupport: !this.state.modalSupport,
        });
    }

    download(url) {
        if(this.state.downloadedFile){
            fileDownload(this.state.downloadedFile, "reward.jpg")
            return
        }
        this.setState({
            downloadingImage: true
        })
        const variables = this.props.variables;
        const downloadImageRequest = {prizeImage: url, code: this.state.winningCode}
        const vm = this;
        // fetch(`http://localhost:5001/photoscrambledev/us-central1/setUpRewardForDownload`, {
        fetch(`https://us-central1-${process.env.REACT_APP_FIREBASE_PROJECT_ID}.cloudfunctions.net/setUpRewardForDownload`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(downloadImageRequest),
        }).then(res => {
            if(res){
                Promise.resolve(res.json()).then(function (value) {
                    console.log(value)
                    if(!value || value.result === "no_prize_sent" || value.error){
                        confirmAlert({
                            variables: variables,
                            title: "Error",
                            message: "Something went wrong",
                            buttons: [
                                {
                                    label: 'OK'
                                }
                            ]
                        })
                        vm.setState({
                            downloadingImage:false
                        })
                    } else {
                        Axios.get(value.newImage, {
                            responseType: 'blob',
                        }).then(res => {
                            fileDownload(res.data, "reward.jpg")
                            vm.setState({
                                downloadingImage:false,
                                downloadedFile: res.data
                            })
                        });
                    }
                }).catch(e =>{
                    confirmAlert({
                        variables: variables,
                        title: "Error",
                        message: "Something went wrong",
                        buttons: [
                            {
                                label: 'OK'
                            }
                        ]
                    })
                    console.log(e)
                    vm.setState({
                        downloadingImage:false
                    })
                })
            } else {
                confirmAlert({
                    variables: variables,
                    title: "Error",
                    message: "Something went wrong",
                    buttons: [
                        {
                            label: 'OK'
                        }
                    ]
                })
                vm.setState({
                    downloadingImage:false
                })
            }
        })
    }

    getLocationPermission(){
        const locationErrorTitle = "Location Error";
        this.getLocation().then(distance_meters => {
            sessionStorage.setItem('locationPermissions', "true");
            const allowed_distance = this.changeMilesToMeters(this.props.variables.acceptableDistance || 100); //In miles
            if(distance_meters <= allowed_distance){
                this.setState({
                    modal:false,
                    loading:false,
                    locationChecking: false
                });
            } else {
                this.setState({
                    modal:false,
                    loading:false
                });
                confirmAlert({
                    title: locationErrorTitle,
                    variables: this.props.variables,
                    message: "Too far from game area to participate!",
                    buttons: [
                        {
                            label: 'Retry',
                            onClick: () => {
                                this.checkUsersLocation()
                            }
                        }
                    ]
                });
            }
        }, error => {
            console.log(error);
            this.setState({
                modal:false,
                loading:false
            });
            sessionStorage.setItem('locationPermissions', "false");
            if(typeof error != "string"){
                error = error.message
            }
            confirmAlert({
                title: locationErrorTitle,
                variables: this.props.variables,
                message: error,
                buttons: [
                    {
                        label: 'Retry',
                        onClick: () => {
                            this.checkUsersLocation()
                        }
                    }
                ]
            });
        })
    }

    checkUsersLocation(){
        const variables = this.props.variables;
        const locationPermissions = sessionStorage.getItem('locationPermissions');
        if(!this.props.variables.collectDistance) {
            this.setState({locationChecking: false})
        } else if(locationPermissions === "false" || !locationPermissions){
            const locationPermissionsHeader = variables.locationPermissionsHeader || "Location Permissions Required";
            const locationPermissionsBody = variables.locationPermissionsBody || "We need your location in order to play! We use this information to make your experience better";
            confirmAlert({
                title: locationPermissionsHeader,
                variables: variables,
                message: locationPermissionsBody,
                buttons: [
                    {
                        label: 'Yes',
                        onClick: () => {
                            this.setState({
                                loading: true,
                            });
                            this.getLocationPermission()
                        }
                    }
                ],
            })
        } else {
            this.setState({
                loading: true,
            });
            this.getLocationPermission()
        }
    }

    logout(){
        sessionStorage.removeItem('userEmail');
        sessionStorage.removeItem('verifiedAge');
        sessionStorage.removeItem('birthday');
        sessionStorage.removeItem('locationPermissions');
        this.setState({
            userEmail:false
        })
    }

    logUserActivity(gameId){
        const vm = this;
        if(gameId){
            let base64EncodedEmail = btoa(this.state.userEmail);
            if(!validateEmail(this.state.userEmail)){
                base64EncodedEmail = this.state.userEmail;
            }
            base.post('userGameHistory/'+base64EncodedEmail+`/${gameId}` , {
                data:gameId,
                then(err){
                    if(!err){
                        vm.setState({
                            alreadyUpdate: gameId,
                        })
                        console.log("user game logged!")
                    }
                }
            })
        }
    }

    onClickPick(pickClicked, position){
        const tenantVariables = this.props.variables || {};
        const frozenHeader = tenantVariables.frozenHeader || "Picks Frozen!"
        const frozenMessage = tenantVariables.frozenMessage || "No more picks are allowed!"
        const alreadyHappenedHeader = tenantVariables.alreadyHappenedHeader || "Oh no!"
        const alreadyHappenedMessage = tenantVariables.alreadyHappenedMessage || "The contest already happened but come back next time to try again!"
        const isMlbApp =  process.env.REACT_APP_IS_MLB_TEAM === "true";
      if(this.state.currentPick.pickid){
          if(isMlbApp){
              confirmAlert({
                  title: 'Sorry!',
                  variables: this.props.variables,
                  message: "We weren't kidding about you being locked in....You're stuck with the your choice.",
                  buttons: [
                    {
                      label: 'OK',
                    }
                  ]
                })
          }
          return;
      }
      if(this.state.status === 2){
        confirmAlert({
          title: alreadyHappenedHeader,
          variables: this.props.variables,
          message: alreadyHappenedMessage,
          buttons: [
            {
              label: 'OK',
            }
          ]
        });
        return;
      } else if(this.state.status === 1){
          confirmAlert({
              title: frozenHeader,
              variables: this.props.variables,
              message: frozenMessage,
              buttons: [
                  {
                      label: 'OK',
                  }
              ]
          });
          return;
      }
      this.setState({
        highlightedPick: pickClicked.id
      })
    }

    onClickCampaignPick(pickClicked){
        if(this.state.campaignPick.pickid){
            return
        }
        this.setState({
            highlightedPick: pickClicked.id || pickClicked.key
        })
    }

    onSubmitCampaignAnswer(){
        const tenantVariables = this.props.variables || {};
        const highlightedPick = this.state.highlightedPick;
        const userEmail = this.state.userEmail;
        let base64EncodedEmail = btoa(userEmail);
        if(!validateEmail(userEmail)){
            base64EncodedEmail = userEmail;
        }
        var postToUserPicks = {};
        var userSelectionObject = {};
        const vm = this;
        userSelectionObject.pickid = highlightedPick;
        userSelectionObject.uid = base64EncodedEmail;
        userSelectionObject.timeStamp = Date.now();
        postToUserPicks["/campaignPicks/" + base64EncodedEmail] = userSelectionObject;
        const seasonLongHeaderPick = tenantVariables.selectCharacterCampaignHeader || "Select This Character?";
        const seasonLongBodyPick = tenantVariables.selectCharacterCampaignBody || "Once you choose you're locked in & can't switch.";
        confirmAlert({
            title: seasonLongHeaderPick,
            variables: vm.props.variables,
            message: seasonLongBodyPick,
            buttons: [
                {
                    label: 'Yes',
                    onClick: () => {
                        appDatabasePrimaryFunctions.ref().update(postToUserPicks, function(error){
                            if(!error){
                                vm.setState({
                                    highlightedPick: false
                                }, ()=>{
                                    const afterPickCampaignHeader = tenantVariables.afterPickCampaignHeader || "Good Luck!";
                                    const afterPickCampaignBody = tenantVariables.afterPickCampaignBody || "We'll see you at the finish line.";
                                    confirmAlert({
                                        title: afterPickCampaignHeader,
                                        variables: vm.props.variables,
                                        message: afterPickCampaignBody,
                                        buttons: [
                                            {
                                                label: "Ok"
                                            }
                                        ]
                                    })
                                })
                            } else {
                                console.log(error);
                                confirmAlert({
                                    title: 'There was an error',
                                    variables: vm.props.variables,
                                    message: error.message,
                                    buttons: [
                                        {
                                            label: 'OK',
                                        }
                                    ]
                                })
                            }
                        })
                    }
                },
                {
                    label: 'No',
                    onClick: () => console.log("dismiss")
                }
            ],
        })
    }

    onSubmitAnswer(){
        const tenantVariables = this.props.variables || {};
        const highlightedPick = this.state.highlightedPick;
        const frozenHeader = tenantVariables.frozenHeader || "Picks Frozen!"
        const frozenMessage = tenantVariables.frozenMessage || "No more picks are allowed!"
        const alreadyHappenedHeader = tenantVariables.alreadyHappenedHeader || "Oh no!"
        const alreadyHappenedMessage = tenantVariables.alreadyHappenedMessage || "The contest already happened but come back next time to try again!"
      if(!highlightedPick){
        return;
      }
        if(this.state.videoState === 2){
            confirmAlert({
                title: alreadyHappenedHeader,
                variables: tenantVariables,
                message: alreadyHappenedMessage,
                buttons: [
                    {
                        label: 'OK',
                    }
                ]
            });
            return;
        } else if(this.state.videoState === 1){
            confirmAlert({
                title: frozenHeader,
                variables: tenantVariables,
                message: frozenMessage,
                buttons: [
                    {
                        label: 'OK',
                    }
                ]
            });
            return;
        }
        const userEmail = this.state.userEmail;
        let base64EncodedEmail = btoa(userEmail);
        if(!validateEmail(userEmail)){
            base64EncodedEmail = userEmail;
        }
      var postToUserPicks = {};
      var userSelectionObject = {};
      const vm = this;
      userSelectionObject.pickid = highlightedPick;
      userSelectionObject.uid = base64EncodedEmail;
      userSelectionObject.timeStamp = Date.now();
      postToUserPicks["/userPicks/" + base64EncodedEmail] = userSelectionObject;
      const gameHeaderPick = tenantVariables.selectCharacterGameHeader || "Select This Character?";
      const gameBodyPick = tenantVariables.selectCharacterGameBody || "Once you choose you're locked in & can't switch.";
      confirmAlert({
          title: gameHeaderPick,
          variables: tenantVariables,
          message: gameBodyPick,
          buttons: [
            {
              label: 'Yes',
              onClick: () => {
                appDatabasePrimaryFunctions.ref().update(postToUserPicks, function(error){
                  if(!error){
                    vm.setState({
                      highlightedPick: false
                    }, ()=>{
                        const afterPickHeader = tenantVariables.afterPickHeader || "Good Luck!";
                        const afterPickBody = tenantVariables.afterPickBody || "We'll see you at the finish line.";
                        confirmAlert({
                            title: afterPickHeader,
                            variables: tenantVariables,
                            message: afterPickBody,
                            messageImage: tenantVariables.confirmationMessageImage,
                            buttons: [
                                {
                                    label: "Ok"
                                }
                            ]
                        })
                    })
                  } else {
                    console.log(error);
                    confirmAlert({
                        title: 'There was an error',
                        variables: tenantVariables,
                        message: error.message,
                        buttons: [
                          {
                            label: 'OK',
                          }
                        ]
                      })
                  }
                })
              }
            },
            {
              label: 'No',
              onClick: () => console.log("dismiss")
            }
          ],
      })
    }

    renderHoldingScreen(){
        const tenantVariables = this.props.variables || {};
        const textAbovePlayingText = tenantVariables.textAbovePlayingText || "Next game starts during the";
        const textColor = tenantVariables.textColor || "#fff";
        return(
            <>
                <div className="hero-text-container">
                  <img onError={()=>this.setState({imageLoading: false})} onLoad={()=> this.setState({imageLoading: false})} src={tenantVariables.frontLogoImage} className="main-hero-image" alt=""/>
                </div>
                <p style={{display: tenantVariables.playingText ? '' : "none", color:textColor}}>{textAbovePlayingText} <br/><span className="emphasizedText" style={{backgroundColor:tenantVariables.primaryColor, color:tenantVariables.secondaryColor}}>{tenantVariables.playingText}</span></p>
            </>
        )
    }

    renderPlayingScreen(){
      const tenantVariables = this.props.variables || {};
      const racers = this.state.racers;
      let videoURL = "";
      for(const racerIndex in racers){
          if(racers[racerIndex].winner){
              if(racers[racerIndex].racerWinningVideoLink){
                  videoURL = racers[racerIndex].racerWinningVideoLink;
              } else if(racers[racerIndex].racerWinningVideoFile){
                  videoURL = racers[racerIndex].racerWinningVideoFile;
              }
          }
      }
      const whoWillWinText = tenantVariables.aboveSelectionsText || "Who Will Win?";
        const isMlbApp =  process.env.REACT_APP_IS_MLB_TEAM === "true";
        const config = {
            file: {
                attributes: {
                    autoPictureInPicture: false,
                    disablePictureInPicture: true,
                    controlsList: "nodownload"
                }
            }
        }
      return(
            <>
                <div className="user" style={{marginTop: 10}}>
                  <img onError={()=>this.setState({imageLoading: false})} onLoad={()=> this.setState({imageLoading: false})} src={tenantVariables.topLeftImage} alt="" style={{marginBottom:0, maxWidth:"100%"}}/>
                </div>
                <div className="top-text">
                  <p className="top-text-mobile" style={{wordWrap: "break-word", fontWeight:700, color: tenantVariables.textColor}}>{whoWillWinText}</p>
                </div>
                <Carousel showArrows={true} showStatus={false} showIndicators={false} showThumbs={false} selectedItem={this.state.selectedItem}
                    onChange={(e) => {
                                this.setState({
                                    selectedItem: e,
                                });
                            }}>
                      {
                        racers.map(function(item,i){
                          if(item.id === this.state.currentPick.pickid || item.id === this.state.highlightedPick){
                            return <div
                                      key={i}
                                      id={item.id}
                                      className="grid-item"
                                      style={{background: tenantVariables.highlightPickColor, boxShadow: "none", border: "none"}}>
                                      <img onError={()=>this.setState({imageLoading: false})} onLoad={()=> this.setState({imageLoading: false})} src={item.gifSelectionImage} style={{maxWidth: 250, maxHeight: 250}} alt="" />
                                    </div>
                          } else {
                            return <div
                                      key={i}
                                      id={item.id}
                                      className="grid-item"
                                      onClick={()=>this.onClickPick(item, i)}
                                      style={{background: 'transparent', boxShadow: "none", border: "none"}}>
                                      <img onError={()=>this.setState({imageLoading: false})} onLoad={()=> this.setState({imageLoading: false})} src={item.gifSelectionImage} style={{maxWidth: 250, maxHeight: 250}} alt=""/>
                                    </div>
                          }
                        }, this)
                      }
                </Carousel>
                <style dangerouslySetInnerHTML={{
                  __html: [
                     '.carousel .control-next.control-arrow:before {',
                     'border-left: 32px solid ' + tenantVariables.highlightPickColor,
                     ';border-top: 16px solid transparent',
                     ';border-bottom: 16px solid transparent',
                     '}',
                     '.carousel .control-prev.control-arrow:before {',
                     'border-right: 32px solid ' + tenantVariables.highlightPickColor,
                     ';border-top: 16px solid transparent',
                     ';border-bottom: 16px solid transparent',
                     '}',
                     '.carousel .thumb.selected, .carousel .thumb:hover {',
                     '  border: 3px solid ' + tenantVariables.highlightPickColor,
                     '}'
                    ].join('\n')
                  }}>
                </style>
                <button className="btn btn-normal" style={{ display: this.state.highlightedPick ? "": "none", color: tenantVariables.secondaryColor, backgroundColor: tenantVariables.primaryColor, marginTop:"25px", width:"300px", fontSize:"1.5rem"}} onClick={()=>this.onSubmitAnswer()}>SUBMIT PICK</button>
                <Modal isOpen={(this.state.modal && videoURL)} style={{width: '90%'}} id="myModal" contentClassName="transparentBackground">
                    {process.env.REACT_APP_FIREBASE_PROJECT_ID === "racechicagobears" ?
                        <ReactPlayer playsinline={false} volume={null} url={videoURL} muted={false} playing={false} onEnded={this.onEnded} controls={true} width="100%" height="auto"/>
                    :
                        <>
                            {isMlbApp ?
                                <ReactPlayer config={config} stopOnUnmount={true} pip={false} playsinline={false} volume={null} url={videoURL} muted={false} playing={false} onEnded={this.onEnded} controls={true} width="100%" height="auto"/>
                                :
                                <ReactPlayer playsinline={true} volume={0} url={videoURL} muted={true} playing={true} onEnded={this.onEnded} controls={false} width="100%" height="auto"/>
                            }
                        </>
                        // <ReactPlayer playsinline={true} volume={0} url={videoURL} muted={true} playing={true} onEnded={this.onEnded} controls={false} width="100%" height="auto"/>
                    }
                </Modal>
            </>
        )
    }

    renderSeasonLongScreen(){
        const tenantVariables = this.props.variables || {};
        const racers = this.state.allRacersList || [];
        const whoWillWinText = tenantVariables.campaignLongInfoText || "Pick Your Season Long Pick";
        return(
            <>
                <div className="user" style={{marginTop: 10}}>
                    <img onError={()=>this.setState({imageLoading: false})} onLoad={()=> this.setState({imageLoading: false})} src={tenantVariables.seasonLongTopLogo || tenantVariables.topLeftImage} alt="" style={{marginBottom:"0"}}/>
                </div>
                <div className="top-text">
                    <p className="top-text-mobile" style={{wordWrap: "break-word", fontWeight:700, color: tenantVariables.textColor}}>{whoWillWinText}</p>
                </div>
                <Carousel showArrows={true} showStatus={false} showIndicators={false} showThumbs={false} selectedItem={this.state.selectedItem}
                          onChange={(e) => {
                              this.setState({
                                  selectedItem: e,
                              });
                          }}>
                    {
                        racers.map(function(item,i){
                            const itemId = item.id || item.key;
                            if(itemId === this.state.campaignPick.pickid || itemId === this.state.highlightedPick){
                                return <div
                                    key={i}
                                    id={itemId}
                                    className="grid-item"
                                    style={{background: tenantVariables.highlightPickColor, boxShadow: "none", border: "none"}}>
                                    <img onError={()=>this.setState({imageLoading: false})} onLoad={()=> this.setState({imageLoading: false})} src={item.gifSelectionImage} style={{maxWidth: 250, maxHeight: 250}} alt="" />
                                </div>
                            } else {
                                return <div
                                    key={i}
                                    id={itemId}
                                    className="grid-item"
                                    onClick={()=>this.onClickCampaignPick(item)}
                                    style={{background: 'transparent', boxShadow: "none", border: "none"}}>
                                    <img onError={()=>this.setState({imageLoading: false})} onLoad={()=> this.setState({imageLoading: false})} src={item.gifSelectionImage} style={{maxWidth: 250, maxHeight: 250}} alt="" />
                                </div>
                            }
                        }, this)
                    }
                </Carousel>
                <style dangerouslySetInnerHTML={{
                    __html: [
                        '.carousel .control-next.control-arrow:before {',
                        'border-left: 32px solid ' + tenantVariables.highlightPickColor,
                        ';border-top: 16px solid transparent',
                        ';border-bottom: 16px solid transparent',
                        '}',
                        '.carousel .control-prev.control-arrow:before {',
                        'border-right: 32px solid ' + tenantVariables.highlightPickColor,
                        ';border-top: 16px solid transparent',
                        ';border-bottom: 16px solid transparent',
                        '}',
                        '.carousel .thumb.selected, .carousel .thumb:hover {',
                        '  border: 3px solid ' + tenantVariables.highlightPickColor,
                        '}'
                    ].join('\n')
                }}>
                </style>
                <button className="btn btn-normal" style={{ display: this.state.highlightedPick ? "": "none", color: tenantVariables.secondaryColor, backgroundColor: tenantVariables.primaryColor, marginTop:"25px", width:"300px", fontSize:"1.5rem"}} onClick={()=>this.onSubmitCampaignAnswer()}>SUBMIT PICK</button>
            </>
        )
    }

    wrapText(context, text, x, y, maxWidth, lineHeight) {
        const words = text.split(' ');
        let line = '';
        let count = 1;

        for(let n = 0; n < words.length; n++) {
            const testLine = line + words[n] + ' ';
            const metrics = context.measureText(testLine);
            const testWidth = metrics.width;
            if (testWidth > maxWidth && n > 0) {
                context.fillText(line, x, y);
                line = words[n] + ' ';
                y += lineHeight;
                count++;
            }
            else {
                line = testLine;
            }
        }
        context.fillText(line, x, y);
        return count*lineHeight;
    }

    fitTextOnCanvas(text,fontFace,yPosition,ctx, maxWidthAllowed){

        // start with a large font size
        let fontsize=45;

        // lower the font size until the text fits the canvas
        do{
            fontsize--;
            ctx.font=fontsize+"px "+fontFace;
        }while(ctx.measureText(text).width> maxWidthAllowed - 20)

        // draw the text
        ctx.fillText(text,maxWidthAllowed/2,yPosition);
    }

    attachWinningImageToCanvas(prizeImage, code){
        const vm = this;
        console.log(prizeImage)

        // let modalBackdrop = document.getElementsByClassName("modal-backdrop")[0];
        // modalBackdrop.style.display = "none";
        let maxWidthAllowed = 300;
        const bodyWidth = Math.max(document.body.offsetWidth, document.body.clientWidth, document.body.scrollWidth);
        if(bodyWidth > 0 && bodyWidth < 300){
            maxWidthAllowed = bodyWidth;
        }
        maxWidthAllowed = maxWidthAllowed - 10;
        let c = document.createElement("canvas");
        let cf = document.createElement("canvas");
        cf.id = "finalCanvas";
        let ctxf = cf.getContext('2d');
        let ctx = c.getContext('2d');
        let img1 = new Image();
        img1.crossOrigin = "anonymous"
        img1.onload= function() {
            const proportionOfImage = img1.width/maxWidthAllowed;
            let height = img1.height/proportionOfImage;
            if(code){
                vm.fitTextOnCanvas(code,"Oswald",(height + 25)* window.devicePixelRatio, ctx, maxWidthAllowed * window.devicePixelRatio);
                cf.height = (height + 50) * window.devicePixelRatio;
                cf.style.height = (height + 50).toString() +'px';
            } else {
                cf.height = (height) * window.devicePixelRatio;
                cf.style.height = (height).toString() +'px';

            }
            cf.style.backgroundColor = "white";
            ctx.fillStyle = "white";
            //Add window.devicePixelRatio to make the image crisp
            cf.width = maxWidthAllowed * window.devicePixelRatio;
            // Style vs directly changing the canvas impacts pixels vs actual size: https://stackoverflow.com/questions/31910043/html5-canvas-drawimage-draws-image-blurry
            cf.style.width = maxWidthAllowed.toString() +'px';
            cf.style.outline = "2px black solid";
            ctxf.textAlign = "center";
            ctxf.textBaseline = "middle";
            ctxf.fillStyle = "white";
            ctxf.font = "25px Oswald";
            if(code){
                vm.fitTextOnCanvas(code,"Oswald",(height + 25)* window.devicePixelRatio, ctxf, maxWidthAllowed * window.devicePixelRatio);
            }
            ctx.drawImage(img1, 0, 0, maxWidthAllowed * window.devicePixelRatio, (img1.height/proportionOfImage)* window.devicePixelRatio);
            ctxf.drawImage(img1, 0, 0, maxWidthAllowed * window.devicePixelRatio, (img1.height/proportionOfImage)* window.devicePixelRatio);
            const finalCouponImage = new Image();
            finalCouponImage.crossOrigin = "anonymous"
            finalCouponImage.src = cf.toDataURL("image/jpeg");
            //we want to keep the true size of the image so when its downloaded it is bigger, not clear the impact of this yet
            finalCouponImage.width = img1.width;
            finalCouponImage.height = img1.height;
            //prevents the image from overflowing
            finalCouponImage.style.maxWidth = "100%";
            finalCouponImage.style.maxHeight = "100%";
            vm.setState({
                winnerModal: true
            }, () => {
                const downloadModalIdElement = document.getElementById("downloadModalId");
                //constrains the image to the visual space
                downloadModalIdElement.style.width = maxWidthAllowed.toString() + "px";
                if(code){
                    downloadModalIdElement.style.height = (height + 50).toString() + "px";
                } else {
                    downloadModalIdElement.style.height = (height).toString() + "px";
                }
                downloadModalIdElement.appendChild(finalCouponImage);
            })
        };
        img1.src = prizeImage;
        console.log(img1)
        ctx.font = "25px Oswald";
    }

    throwWinningAlert(){
        const tenantVariables = this.props.variables || {};
        const buttonsArray = [];
        const allowedProjects = ["bluejays4th5th6th", "bluejays4th5th6thdev"]
        if(process.env.REACT_APP_IS_MLB_TEAM === "true" && allowedProjects.indexOf(process.env.REACT_APP_FIREBASE_PROJECT_ID) !== -1){
            let fetchUrl = `https://myportal.bluejays.com/PortalTest/provider/71?userid=${this.state.userEmail}&win=true`;
            if(process.env.NODE_ENV === "production" && process.env.REACT_APP_FIREBASE_PROJECT_ID === "bluejays4th5th6th"){
                fetchUrl = `https://myportal.bluejays.com/provider/71?userid=${this.state.userEmail}&win=true`;
            }
            buttonsArray.push({label: "OK", onClick: () => window.open(fetchUrl)})
        }
        if(tenantVariables.doNotCollectEmail){
            console.log("HERE")
          // this.setState({
          //     winnerModal: true
          // }, () => {
          // })
        } else {
            let winnerHeader = tenantVariables.winningHeader || "You Win!";
            let winnerMessage = tenantVariables.winningMessage || "Congratulations";
            if(!this.state.wonPrize){
                winnerHeader = tenantVariables.winningNoPrizeHeader || "You Win!";
                winnerMessage = tenantVariables.winningNoPrizeMessage || "Unfortunately all prizes have been given out";
            }
            confirmAlert({
                title: winnerHeader,
                variables: tenantVariables,
                message: winnerMessage,
                buttons:buttonsArray
            })
        }
    }

    throwLosingAlert(){
      const tenantVariables = this.props.variables || {};
      // " Looks like you got the answer wrong. Come back next time to try again!"
        let losingHeader = tenantVariables.losingHeader || "Oh No!";
        let losingMessage = tenantVariables.losingMessage || "Looks like you got the answer wrong. Come back next time to try again!";
        const buttonsArray = [];
        const allowedProjects = ["bluejays4th5th6th", "bluejays4th5th6thdev"]
        if(process.env.REACT_APP_IS_MLB_TEAM === "true" && allowedProjects.indexOf(process.env.REACT_APP_FIREBASE_PROJECT_ID) !== -1){
            let fetchUrl = `https://myportal.bluejays.com/PortalTest/provider/71?userid=${this.state.userEmail}&win=false`;
            if(process.env.NODE_ENV === "production" && process.env.REACT_APP_FIREBASE_PROJECT_ID === "bluejays4th5th6th"){
                fetchUrl = `https://myportal.bluejays.com/provider/71?userid=${this.state.userEmail}&win=false`;
            }
            buttonsArray.push({label: "OK", onClick: () => window.open(fetchUrl)})
        }
        console.log(this.state.wonPrize)
        if(this.state.wonPrize){
            losingHeader = tenantVariables.losingHeaderPrize || "You Lose!";
            losingMessage = tenantVariables.losingMessagePrize || "But you still get a prize!";
        }
        confirmAlert({
            title: losingHeader,
            variables: tenantVariables,
            message: losingMessage,
            buttons: buttonsArray
        })
    }

    throwNotInTimeAlert(){
        const tenantVariables = this.props.variables || {};
        const alreadyHappenedHeader = tenantVariables.alreadyHappenedHeader || "Oh no!"
        const alreadyHappenedMessage = tenantVariables.alreadyHappenedMessage || "The contest already happened but come back next time to try again!"
        const buttonsArray = [];
        const allowedProjects = ["bluejays4th5th6th", "bluejays4th5th6thdev", "mlbdevracegame"]
        if(process.env.REACT_APP_IS_MLB_TEAM === "true" && allowedProjects.indexOf(process.env.REACT_APP_FIREBASE_PROJECT_ID) !== -1){
            let fetchUrl = `https://myportal.bluejays.com/PortalTest/provider/71?userid=${this.state.userEmail}&win=false`;
            if(process.env.NODE_ENV === "production" && process.env.REACT_APP_FIREBASE_PROJECT_ID === "bluejays4th5th6th"){
                fetchUrl = `https://myportal.bluejays.com/provider/71?userid=${this.state.userEmail}&win=false`;
            }
            buttonsArray.push({label: "OK", onClick: () => window.open(fetchUrl)})
        }
        confirmAlert({
            title: alreadyHappenedHeader,
            variables: this.props.variables,
            message: alreadyHappenedMessage,
            buttons: buttonsArray
        })
    }

    alertIfWonOrLost(){
        const winners = []
        const racers = this.state.racers || [];
        for(const i in racers){
            const racer = racers[i];
            if(racer.winner){
                winners.push(racer.id);
            }
        }
        if(typeof this.state.currentPick.pickid === "undefined"){
            this.throwNotInTimeAlert();
        } else if(winners.indexOf(this.state.currentPick.pickid) !== -1){
            this.throwWinningAlert();
            this.setState({
                startConfetti: true
            })
        } else {
            this.throwLosingAlert()
        }
    }

    render() {
        const tenantVariables = this.props.variables || {};
        const tenantRules = this.state.tenantRules || {};
        const location = window.location.pathname;
        const logOutButtonColor = tenantVariables.logOutButtonColor || "white";
        const primaryColor = tenantVariables.primaryColor || "black";
        const secondaryColor = tenantVariables.secondaryColor || "white";
        const rulesInAppButtonText = tenantRules.rulesInAppButtonText || "Rules & Regs";
        const mlbPrivacyPolicyLink = tenantRules.mlbPrivacyPolicyLink || "https://www.mlb.com/app/ballpark/official-information/privacy-policy";
        let rulesShowInAppPopUpHeader = tenantRules.rulesShowInAppPopUpHeader;
        let rulesShowInAppPopUpText = tenantRules.rulesShowInAppPopUpText;
        let rulesPopUpText = tenantRules.rulesPopUpText;
        let rulesPopUpHeader = tenantRules.rulesPopUpHeader;
        const supportHeader = tenantRules.supportHeader || "NEED SUPPORT?";
        const supportText = tenantRules.supportText || "Email customerservice@website.mlb.com with your issue and we'll be in touch!";
        const link = tenantRules.rulesAndRegsLink;
        const isMlbApp =  process.env.REACT_APP_IS_MLB_TEAM === "true";
        const rulesShowInApp = tenantRules.rulesShowInApp || isMlbApp || false;
        if(rulesShowInApp) {
            if(!rulesShowInAppPopUpText && rulesPopUpText){
                rulesShowInAppPopUpText = rulesPopUpText;
            }
            if(!rulesShowInAppPopUpHeader && rulesPopUpHeader){
                rulesShowInAppPopUpHeader = rulesPopUpHeader;
            }
        }
        if(!this.state.userEmail){
          let nextUrl = 'login'
          if(location === "/seasonlong"){
              nextUrl = "/login?nexturl=seasonlong"
          }
            return <Redirect to={nextUrl} />
        } else if(!tenantVariables.doNotCollectEmail && !isMlbApp && !validateEmail(this.state.userEmail)){
            this.logout();
        } else if((tenantVariables.doNotCollectEmail || isMlbApp) && validateEmail(this.state.userEmail)){
            this.logout();
        }
        if (this.state.loading === true) {
            return (
                <Loading primaryColor={tenantVariables.primaryColor} loading={this.state.loading} backgroundImage={tenantVariables.backgroundImage}/>
            )
        }
        let renderHoldingScreen = false;
        let renderMainScreen = "";
        if(location === "/seasonlong"){
            renderMainScreen = this.renderSeasonLongScreen()
        } else if(this.state.gameActive && !this.state.locationChecking){
            renderMainScreen = this.renderPlayingScreen()
        } else {
            renderMainScreen = this.renderHoldingScreen()
            renderHoldingScreen = true;
        }
        let maxWidthAllowed = 300;
        const bodyWidth = Math.max(document.body.offsetWidth, document.body.clientWidth, document.body.scrollWidth);
        if(bodyWidth > 0 && bodyWidth < 300){
            maxWidthAllowed = bodyWidth;
        }
        return(
          <div className="flex-container-home" style={{backgroundImage: "url(" + tenantVariables.backgroundImage + ")"}}>
              {this.state.startConfetti &&
                <Confetti width={this.state.width} height={this.state.height} style={{zIndex: 1 }}/>
              }
              <div className="flex-content-container-home">
                  <div className="intro-container-home" style={{margin: "auto"}}>
                      <div className="grid-wrapper">
                          {(!this.state.imageLoading || (!tenantVariables.frontLogoImage && renderHoldingScreen)) &&
                              <div className="flex-header-home" style={{marginTop:10, justifyContent: "space-between", maxWidth: "100%", minWidth: 280, width:"100vw"}}>
                                  {isMlbApp &&
                                      <div style={{width: "25%", height: 38, color: logOutButtonColor, borderColor: logOutButtonColor, border: "solid 1px", borderRadius: 5, flexGrow:1, maxWidth: 100}}>
                                          <button className="btn" style={{color: logOutButtonColor, width: "100%", height: "100%", backgroundColor: "transparent", padding: "6px 12px"}} onClick={() => { this.toggleSupport() }}>
                                              <Textfit mode="single" max={16}>
                                                  Support
                                              </Textfit>
                                          </button>
                                      </div>
                                  }
                                  {isMlbApp &&
                                      <div style={{ width: "25%", color: logOutButtonColor, borderColor: logOutButtonColor, height: 38, border: "solid 1px", borderRadius: 5, flexGrow:1, maxWidth: 100}}>
                                          <button className="btn" style={{color: logOutButtonColor, width: "100%", height: "100%", backgroundColor: "transparent", padding: "6px 12px"}} onClick={() => {window.open("https://www.mlb.com/app/ballpark/official-information/terms-of-use", "_blank")}}>
                                              <Textfit mode="single" max={16}>
                                                  MLB TOU
                                              </Textfit>
                                          </button>
                                      </div>
                                  }
                                  {isMlbApp &&
                                      <div style={{ width: "25%", height: 38, color: logOutButtonColor, borderColor: logOutButtonColor, border: "solid 1px", borderRadius: 5, flexGrow:1, maxWidth: 100}}>
                                          <button className="btn" style={{color: logOutButtonColor, width: "100%", height: "100%", backgroundColor: "transparent", padding: "6px 12px"}} onClick={() => { window.open(mlbPrivacyPolicyLink, "_blank") }}>
                                              <Textfit mode="single" max={16}>
                                                  MLB Privacy Policy
                                              </Textfit>
                                          </button>
                                      </div>
                                  }
                                  {rulesShowInApp &&
                                      <div style={{width: "25%", height: 38, color: logOutButtonColor, borderColor: logOutButtonColor, border: "solid 1px", borderRadius: 5, maxWidth: 100}}>
                                          <button className="btn" style={{color: logOutButtonColor, width: "100%", height: "100%", backgroundColor: "transparent", padding: "6px 12px"}} onClick={() => { rulesShowInAppPopUpText?this.toggleRules():window.open(link, '_blank') }}>
                                              <Textfit mode="single" max={16}>
                                                  {rulesInAppButtonText}
                                              </Textfit>
                                          </button>
                                      </div>
                                  }
                                  <div style={{display: (isMlbApp || this.props.passedEmail) && "none", width: "25%", height: 38, color: logOutButtonColor, borderColor: logOutButtonColor, border: "solid 1px", borderRadius: 5, marginLeft:"auto", maxWidth: 80}}>
                                      <button className="btn" onClick={() => { this.logout() }} style={{color: logOutButtonColor, width: "100%", height: "100%", backgroundColor: "transparent", padding: "6px 12px"}}>
                                          <Textfit mode="single" max={16}>
                                              LOG OUT
                                          </Textfit>
                                      </button>
                                  </div>
                              </div>
                          }
                          {renderMainScreen}
                      </div>
                  </div>
              </div>
              <Modal isOpen={this.state.winnerModal} id="myOtherModal">
                  <center style={{maxWidth:maxWidthAllowed, marginTop:10, marginBottom: 10}}>
                      <span className="unselectable">Hold your finger on the box to save this coupon to your photos<br/>👇👇👇👇👇👇</span>
                      <div className="unselectable" style={{height:10, width: "100%"}}/>
                      <center id="downloadModalId"/>
                  </center>
              </Modal>
              <Modal isOpen={this.state.modalRules} style={{width: '90%'}} id="rulesModal">
                  <ModalHeader style={{color: secondaryColor, padding:10}}>
                    <span style={{fontSize:25}}>
                        {rulesShowInAppPopUpHeader}
                    </span>
                  </ModalHeader>
                  <ModalBody>
                      <center className="container-out">
                          <div className="question-box question-form">
                              <p style={{width:"100%",height:200, overflow:"scroll",padding:10, borderBottom:"1px solid black", borderTop: "1px solid black", fontWeight:100, fontSize:14}} dangerouslySetInnerHTML={{ __html:rulesShowInAppPopUpText}}/>
                              <button className="btn btn-default btn-admin btn-cancel" onClick={() => { this.toggleRules(); }} style={{color: secondaryColor, backgroundColor: primaryColor, fontWeight: '700', marginTop:'20px', fontSize:'1.3 rem'}}>Dismiss</button>
                          </div>
                      </center>
                  </ModalBody>
              </Modal>
              <Modal isOpen={this.state.modalSupport} style={{width: '90%'}} id="rulesModal">
                  <ModalHeader style={{color: secondaryColor, padding:10}}>
                    <span style={{fontSize:25}}>
                        {supportHeader}
                    </span>
                  </ModalHeader>
                  <ModalBody>
                      <center className="container-out">
                          <div className="question-box question-form">
                              <p style={{width:"100%",overflow:"scroll",fontWeight:100, fontSize:14, marginBottom: 0}} dangerouslySetInnerHTML={{ __html:supportText}}/>
                              <button className="btn btn-default btn-admin btn-cancel" onClick={() => { this.toggleSupport(); }} style={{color: secondaryColor, backgroundColor: primaryColor, fontWeight: '700', fontSize:'1.3 rem'}}>Dismiss</button>
                          </div>
                      </center>
                  </ModalBody>
              </Modal>
          </div>
        )
    }
}

export default Main_App;
